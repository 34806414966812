@font-face {
  font-family: 'Quicksand-Medium';
  src: url('./fonts/Quicksand-Medium/fonts/Quicksand-Medium.eot');
  src: local('☺'), url('./fonts/Quicksand-Medium/fonts/Quicksand-Medium.woff') format('woff'), url('./fonts/Quicksand-Medium/fonts/Quicksand-Medium.ttf') format('truetype'), url('./fonts/Quicksand-Medium/fonts/Quicksand-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./fonts/Quicksand-Regular/fonts/Quicksand-Regular.eot');
  src: local('☺'), url('./fonts/Quicksand-Regular/fonts/Quicksand-Regular.woff') format('woff'), url('./fonts/Quicksand-Regular/fonts/Quicksand-Regular.ttf') format('truetype'), url('./fonts/Quicksand-Regular/fonts/Quicksand-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand-SemiBold';
  src: url('./fonts/Quicksand-SemiBold/fonts/Quicksand-SemiBold.eot');
  src: local('☺'), url('./fonts/Quicksand-SemiBold/fonts/Quicksand-SemiBold.woff') format('woff'), url('./fonts/Quicksand-SemiBold/fonts/Quicksand-SemiBold.ttf') format('truetype'), url('./fonts/Quicksand-SemiBold/fonts/Quicksand-SemiBold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand-Bold';
  src: url('./fonts/Quicksand-Bold/fonts/Quicksand-Bold.eot');
  src: local('☺'), url('./fonts/Quicksand-Bold/fonts/Quicksand-Bold.woff') format('woff'), url('./fonts/Quicksand-Bold/fonts/Quicksand-Bold.ttf') format('truetype'), url('./fonts/Quicksand-Bold/fonts/Quicksand-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

body, div, a, h1, table, input, label, legend {
  font-family: 'Quicksand', sans-serif;
}

body {
  margin: 0;
  font-size: 0.875rem;
  font-family: 'Quicksand', sans-serif;
  line-height: 1.43;
  letter-spacing: 0;
}

/***************login*****************************/

input.MuiInputBase-input {
  font-family: "Quicksand-Medium";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  color: #757575;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-size: 14px;
  font-family: "Quicksand-Medium";
}

.MuiFormHelperText-root.Mui-error {
  color: #E93529;
  font-family: "Quicksand";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
}

.MuiFormHelperText-root.Mui-error {
  color: #E93529;
  margin-left: 0px;
}

.Mui-error span.error_message {
  display: flex;
  margin: 5px 0px 0px;
}

span.warn_message {
  color: "#c4c4c4";
  display: flex;
  margin: 5px 0px 0px;
}

.Mui-error svg {
  width: 16px;
  height: 16px;
  margin-right: 4px
}

button.password_icon.MuiIconButton-edgeEnd {
  color: #9E9E9E;
}

button.password_icon.MuiIconButton-edgeEnd svg {
  width: 24px;
  height: 24px;
}

.action_form .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #DADCE0;
}

.action_form .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline, .action_form .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #E93529;
}

/* .action_form label.MuiInputLabel-outlined {
  color: #757575;
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
} */

.action_form .MuiOutlinedInput-notchedOutline {
  border: 1px solid #DADCE0;
  border-radius: 4px;
}

.action_form .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: #1BA785;
}

/**************list******************/

.item_list {
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  border-left: solid 4px transparent;
}

.nav-item.MuiListItem-gutters.MuiListItem-button.active .item_list {
  border-left: solid 4px #1BA785;
}

.nav-item.active .item_list div {
  color: #1BA785;
}

.item_list div {
  color: #757575;
  font-family: "Quicksand-Medium";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px
}

.MuiListItem-button.item_a:hover {
  /* border-left:solid 4px transparent;
    background-color: rgba(0, 0, 0, 0.04); */
}

.filter_heading {
  color: #424242;
  font-family: "Quicksand-Medium";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -18px;
  padding: 0px 0px 20px;
}

.action_form label.MuiInputLabel-outlined {
  font-family: "Quicksand-Medium";
  font-size: 16px;
}

.action_form .MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-family: "Quicksand-Medium";
  font-size: 14px;
}

.button_text_sec .clear_form, .button_text_sec .clear_form:hover {
  color: #1BA785;
  font-family: "Quicksand";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  background-color: transparent;
}

.button_text_sec .clear_form:hover {
  text-decoration: underline;
}

.remember_text.button_text_sec {
  color: #757575;
}

button.filter_btn, button.filter_btn:hover {
  color: #1BA785;
  font-family: "Quicksand-Bold";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  height: 38px;
  width: 108px;
  border: 1px solid #EEEEEE;
  border-radius: 2px;
  background-color: #FFFFFF;
  margin-right: 10px;
}

button.MuiButtonBase-root {
  text-transform: inherit;
}

button.MuiButton-textPrimary:hover {
  /* background-color:transparent; */
}

button.delete_all_btn svg {
  height: 20px;
  width: 20px;
  color: #1BA785;
}

button.delete_all_btn {
  height: 38px;
  width: 108px;
  border: 1px solid #EEEEEE;
  border-radius: 3px;
  background-color: #FFFFFF;
  color: #1BA785;
  font-family: "Quicksand-Bold";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.swipeableDrawer div {
  left: auto;
  right: -20px!important;
}

button.create_btn svg, button.filter_btn svg {
  widows: 16px;
  height: 16px;
}

button.create_btn, button.create_btn:hover {
  color: #FFFFFF;
  font-family: "Quicksand-Bold";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  height: 38px;
  width: 108px;
  border-radius: 3px;
  background-color: #1BA785;
}

.paper_box .arrowIcon {
  height: 16px;
  width: 16px;
}

.action_form .action_login_button:hover, .action_form .action_login_button {
  color: #FFFFFF;
  font-family: "Quicksand-Bold";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  height: 48px;
  width: 120px;
  border-radius: 4px;
  background-color: #1BA785;
  margin: 10px 0px;
  text-transform: inherit;
  box-shadow: none;
}

.action_form .disabled_login_button {
  color: #ffffff !important;
  background-color: #d4d3d3 !important;
}

.login_content {
  color: #FFFFFF;
  font-family: "Quicksand-SemiBold";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
}

.login_header h1 {
  color: #212121;
  font-family: "Quicksand-Medium";
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 34px;
}

.login_header p {
  color: #212121;
  font-family: "Quicksand-Medium";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  padding-bottom: 6px;
}

.button_text_sec {
  display: flex;
}

.button_text_sec, .button_text_sec a {
  font-family: "Quicksand-Medium";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.delete_dialog .MuiDialog-paperWidthSm {
  background-color: #FFFFFF;
  box-shadow: -20px 0 40px 0 rgba(0, 0, 0, 0.1);
  width: 500px;
  border-radius: 0;
}

.close_dialog {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 24px;
  height: 24px;
}

.cell_name:hover {
  color: #1BA785;
}

.delete_dialog .MuiDialogActions-root {
  justify-content: center;
  padding-bottom: 50px;
}

.delete_dialog .delete_dialog_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px 0px;
}

.badge {
  border-radius: 2px;
  font-family: "Quicksand-Bold";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  height: 20px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge.badge-success {
  color: #36C14D;
  background-color: #E0FEE5
}

.badge.badge-danger {
  color: #E93529;
  background-color: #FFE6E4
}

.delete_dialog h2 {
  margin-bottom: 0;
  color: #424242;
  font-family: "Quicksand-SemiBold";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
}

.delete_dialog p {
  color: #757575;
  font-family: "Quicksand-Medium";
  font-size: 16px;
  letter-spacing: 0;
  margin-top: 5px;
  line-height: 20px;
  text-align: center;
}

button.MuiButton-textPrimary.no {
  color: #1BA785;
  font-family: "Quicksand-Bold";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  height: 42px;
  width: 96px;
  border: 1px solid #EEEEEE;
  border-radius: 3px;
  background-color: #FFFFFF;
}

button.MuiButton-textPrimary.yes {
  height: 42px;
  width: 96px;
  border-radius: 3px;
  background-color: #1BA785;
  color: #FFFFFF;
  font-family: "Quicksand-Bold";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e8ecf1;
  border-radius: 0.25rem;
}

.user_menu {
  margin: 0;
  width: 160px;
  box-sizing: border-box;
  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
  border-top: transparent;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 12px 0px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #343a40;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.icon-options-vertical {
  cursor: pointer;
  height: 28px;
  color: #9E9E9E;
  width: 24px;
  text-align: center;
  margin-right: 20px;
}

.user_menu a {
  color: #212121;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
}

article .MuiTableRow-root.Mui-selected, article .MuiTableRow-root.Mui-selected:hover {
  background-color: transparent;
}

article .MuiPaper-root table tr th.selectedAll {
  padding: 0;
}

article .MuiPaper-root table tr td {
  padding: 17px 0px;
  letter-spacing: 0;
  color: #424242;
  font-family: "Quicksand-Medium";
  font-size: 14px;
  /* font-weight: 500; */
  letter-spacing: 0;
  line-height: 18px;
  max-width: 200px;
  line-break: anywhere;
}

article .MuiPaper-root table tr th {
  color: #424242;
  font-family: "Quicksand-Medium";
  font-size: 14px;
  /* font-weight: 500; */
  letter-spacing: 0;
  line-height: 18px;
  padding: 17px 0px;
  letter-spacing: 0;
}

article .MuiPaper-root table tr td .MuiCheckbox-colorSecondary, article .MuiPaper-root table tr th .MuiCheckbox-colorSecondary {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  padding-left: 15px;
}

.view_header {
  color: #424242;
  font-family: "Quicksand";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 30px;
}

.step_header {
  color: #424242;
  font-family: "Quicksand";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 20px;
}

.MuiAutocomplete-popper {
  border: 1px solid #EEEEEE;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08)!important;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #fAfAfA;
}

.users_menu .dropdown-item {
  color: #757575;
  font-family: "Quicksand-Medium";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  display: flex;
  align-items: center;
  padding: 0.6rem 0.8rem;
}

.dropdown-menu.users_menu.active {
  display: block;
  width: 160px;
  padding: 0 0px;
  min-width: 160px;
  color: #212121;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: transparent !important;
}

.file_upload {
  border: 1px solid #DADCE0;
  border-radius: 4px;
  background-color: #FAFAFA;
  padding: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file_upload p {
  color: #757575;
  font-family: "Quicksand-Medium";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  padding: 0;
  margin: 15px 0px 0px;
}

.file_upload .contained_label {
  width: 100%;
  display: flex;
  align-items: center;justify-content:center;
}
.file_upload .contained_label .upload_button{
  color: #FFFFFF;
  font-family: "Quicksand-Bold";
  font-size: 14px; 
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}
.file_upload .contained_label .upload_button.MuiButton-containedPrimary {
  color: #fff;
  background-color: #1BA785;
  height: 42px;
  width: 108px;
  border-radius: 3px;
  background-color: #1BA785;
}
.file_upload .head_text {
  color: #212121;
  font-family: "Quicksand-Medium";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  padding-bottom: 15px;
}
.loading{
  position: absolute;
  background: #0000002e;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload_button {}

.MuiAutocomplete-listbox .MuiAutocomplete-option {
  padding: 16px 16px;
  color: #757575;
  font-family: "Quicksand-Medium";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option span {
  padding: 0
}

.users_menu i {
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  color: rgba(0, 0, 0, 0.54);
  display: inline-flex;
  min-width: 20px;
  flex-shrink: 0;
  margin: 0px 10px 0px 0px;
}

.scheme_icon {
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  color: rgba(0, 0, 0, 0.54);
  display: inline-flex;
  min-width: 24px;
  flex-shrink: 0;
  margin: 0px 0px 0px 0px;
  background-image: url(./Assets/scheme_outline.svg);
}

.active .scheme_icon {
  background-image: url(./Assets/scheme_filled.svg);
}

.active_icon {
  background-image: url(./Assets/active.svg);
}

.view_icon {
  background-image: url(./Assets/view.svg);
}

.Inactive_icon {
  background-image: url(./Assets/inactive.svg);
}

.edit_icon {
  background-image: url(./Assets/edit.svg);
}

.delete_icon {
  background-image: url(./Assets/delete.svg);
}

.steper_wraper {
  width: 100%;
  padding-left: 3px;
  padding-right: 5px;
}

.steper_wraper input.MuiInputBase-input {
  background: #fff;
}

.steper_wraper .MuiStepper-horizontal {
  background-color: transparent;
  padding: 5px 10px 5px 10px;
  width: 100%;
  display: flex;
}

.cursorActive, .cursorActive div, .cursorActive span, .cursorActive img {
  cursor: pointer;
}

.steper_wraper .MuiStepLabel-label.MuiStepLabel-completed {
  font-weight: 500;
  color: #424242;
  font-family: "Quicksand";
  line-height: 15px;
  letter-spacing: 0;
}

.steper_wraper .MuiStepLabel-label {
  color: #9E9E9E;
  font-family: "Quicksand";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}

.steper_wraper .MuiStep-horizontal {
  padding-left: 0px;
  padding-right: 0px;
  width: 26%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 35px;
}

.steper_wraper .MuiStep-horizontal:last-child {
  width: 130px;
  justify-content: flex-end;
}

.steper_wraper .progress_box {
  width: 100%
}

.steper_wraper .progress_box div {
  width: 100%;
}

.steper_wraper .MuiLinearProgress-root {
  height: 2px;
}

.steper_wraper .MuiLinearProgress-barColorPrimary {
  background-color: #424242;
}

.steper_wraper .MuiLinearProgress-colorPrimary {
  background-color: #eeeeee;
}

.steper_wraper .MuiStepLabel-iconContainer img {
  width: 14px;
  height: 14px;
}

.step_action {
  border: 1px solid #EEEEEE;
  border-radius: 0 0 4px 4px;
  background-color: #FFFFFF;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.04);
  padding: 10px 40px;
}

.last_range_row div {
  text-align: right;
  padding: 4px 0px;
}

.deleteAccordRangeIcon {
  width: 100%;
  text-align: right;
  padding: 10px 10px;
}

.deleteAccordRangeIcon img {
  cursor: pointer;
}

.action_form .MuiOutlinedInput-notchedOutline {}

button.btn_addForm, button.btn_addForm:hover {
  color: #1BA785;
  font-family: "Quicksand";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  padding-left: 0;
  line-height: 15px;
  background-color: transparent;
}

button.btn_addForm svg, button.btn_addForm svg:hover {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.action_form .MuiAccordion-root:before {
  background-color: transparent;
}

.steper_wraper .MuiAccordionSummary-root {
  padding: 0px 16px;
  min-height: 74px;
}

.steper_wraper .MuiAccordionSummary-root.Mui-expanded {
  min-height: 74px;
}

.DatePicker {
  width: 100%;
}

.steper_wraper .MuiAccordion-root.Mui-expanded {
  padding: 0px 14px;
}

.last_grid_row div {
  padding: 10px 25px 10px;
  text-align: right;
}

.deleteAccordIcon {
  position: absolute;
  right: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15px;
  height: 40px;
  width: 50px;
}

.paper_scroll {
  width: 100%;
  /* max-height: 500px;
  overflow-y: auto; */
}

/* Pagination */

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  justify-content: center;
  list-style: none;
  border-radius: 0.25rem;
  border-radius: 2px !important;
}

.page-item.next-arrow, .page-item.prev-arrow, .page-item.next-arrow:hover, .page-item.prev-arrow:hover {
  background-color: transparent;
  border: 1px solid transparent !important;
  text-decoration: none !important;
}

.page-item.next-arrow a, .page-item.prev-arrow a {}

.page-item.next-arrow i, .page-item.prev-arrow i {
  font-weight: 900;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  justify-content: center;
  align-items: center;
  display: flex;
  /* background-color: #fff;
    border: 1px solid #dee2e6; */
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.pagination .page-item .page-link {
  color: #212121;
  font-family: Quicksand;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  text-decoration: none;
  cursor: pointer;
}

.pagination .page-item .page-link:focus {
  background: inherit;
}

.MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
  opacity: 1;
  background: #F5F5F5;
  cursor: not-allowed;
}

.pagination .page-item .page-link i:before {
  font-size: inherit;
  line-height: 1;
  vertical-align: middle;
}

.backArrow svg {
  color: #424242;
}

.view_form.action_form .MuiInputBase-input.Mui-disabled, .view_form.action_form .MuiInputBase-root.Mui-disabled {
  background-color: #fff;
}

.backArrow {
  top: 0px;
  position: fixed;
  z-index: 1202;
  background: #fff;
  width: 70px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

label.formRedio span.MuiFormControlLabel-label {
  color: #424242;
  font-family: "Quicksand-Medium";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: normal;
}

.formRedio .MuiRadio-root {
  color: #1BA785;
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

label.formRedio.MuiFormControlLabel-root {
  margin-right: 30px
}

.action_form .MuiInputBase-input.Mui-disabled {
  opacity: 1;
  background: #F5F5F5;
  cursor: not-allowed;
}

.action_form .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #DADCE0;
}

.pagination .page-item.active .page-link, .pagination .page-item:hover .page-link, .pagination .page-item:focus .page-link, .pagination .page-item:active .page-link {
  background: #1BA785;
  border-color: #1BA785;
  color: #ffffff;
}

.pagination.flat .page-item .page-link {
  border: none;
  border-radius: 2px;
}

.pagination.separated .page-item {
  margin-left: 7px;
  margin-right: 7px;
  width: 32px;
  height: 32px;
  border: 1px solid transparent;
  border-radius: 100%;
  overflow: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination.separated .page-item:hover {
  border-color: #EEEEEE;
  background-color: #EEEEEE;
}

.pagination.separated .page-item:hover a, .pagination.separated .page-item:hover {
  background: none;
  color: #212121;
}

.pagination.separated .page-item .active:hover {}

.pagination.separated .page-item:first-child {
  margin-left: 0;
}

.pagination.separated .page-item:last-child {
  margin-right: 0;
}

/* .pagination.separated .page-item .page-link {
    border-radius: 2px;
  } */

.pagination.rounded .page-item:first-child .page-link, .pagination.loader-demo-box .page-item:first-child .page-link {
  border-radius: 25px 0 0 25px;
}

.pagination.rounded .page-item:last-child .page-link, .pagination.loader-demo-box .page-item:last-child .page-link {
  border-radius: 0 25px 25px 0;
}

.pagination.rounded-flat .page-item {
  margin-right: 3px;
  margin-left: 3px;
}

.pagination.rounded-flat .page-item .page-link {
  border: none;
  border-radius: 50px;
}

.pagination.rounded-separated .page-item {
  margin-left: 2px;
  margin-right: 2px;
}

.pagination.rounded-separated .page-item:first-child {
  margin-left: 0;
}

.pagination.rounded-separated .page-item:first-child .page-link {
  border-radius: 10px 0 0 10px;
}

.pagination.rounded-separated .page-item:last-child {
  margin-right: 0;
}

.pagination.rounded-separated .page-item:last-child .page-link {
  border-radius: 0 10px 10px 0;
}

.pagination.rounded-separated .page-item .page-link {
  border-radius: 2px;
}

/* pagination variations */

.pagination-primary .page-item.active .page-link {
  background: #3DB54A;
  border-color: #3DB54A;
}

.pagination-primary .page-item .page-link:hover {
  background: #2fe1e6;
  border-color: #3DB54A;
  color: #ffffff;
}

.pagination-secondary .page-item.active .page-link {
  background: #d8d8d8;
  border-color: #d8d8d8;
}

.pagination-secondary .page-item .page-link:hover {
  background: #e5e5e5;
  border-color: #d8d8d8;
  color: #ffffff;
}

.pagination-success .page-item.active .page-link {
  background: #38ce3c;
  border-color: #38ce3c;
}

.pagination-success .page-item .page-link:hover {
  background: #4cd350;
  border-color: #38ce3c;
  color: #ffffff;
}

.recon-pagination .text-muted {
  padding: 0px 20px;
}

.recon-pagination p {
  padding: 0px 5px;
}

.pagination-info .page-item.active, .pagination-info .page-item.active:hover {
  background: #3DB54A;
  border-color: #3DB54A;
  color: #ffffff;
}

.pagination-info .page-item.active a, .pagination-info .page-item.active:hover a {
  color: #ffffff;
}

.pagination-warning .page-item.active .page-link {
  background: #ffde73;
  border-color: #ffde73;
}

.pagination-warning .page-item .page-link:hover {
  background: #ffe48d;
  border-color: #ffde73;
  color: #ffffff;
}

.pagination-danger .page-item.active .page-link {
  background: #ff4d6b;
  border-color: #ff4d6b;
}

.pagination-danger .page-item .page-link:hover {
  background: #ff6780;
  border-color: #ff4d6b;
  color: #ffffff;
}

.pagination-light .page-item.active .page-link {
  background: #f8f9fa;
  border-color: #f8f9fa;
}

.pagination-light .page-item .page-link:hover {
  background: white;
  border-color: #f8f9fa;
  color: #ffffff;
}

.pagination-dark .page-item.active .page-link {
  background: #3e4b5b;
  border-color: #3e4b5b;
}

.pagination-dark .page-item .page-link:hover {
  background: #48586a;
  border-color: #3e4b5b;
  color: #ffffff;
}

/***************/

.login_form .email fieldset legend {
  width: 105px;
}

.forgot_form .forgot_email fieldset legend {
  width: 68px;
}

.filter_form .name_field fieldset legend {
  width: 92px;
}

.filter_form .id_field fieldset legend {
  width: 74px;
}

.filter_form .email_field fieldset legend {
  width: 91px;
}

.merchant_form {}

.merchant_form .name_field fieldset legend {
  width: 40px;
}

.merchant_form .id_field fieldset legend {
  width: 20px;
}

.merchant_form .email_field fieldset legend {
  width: 82px;
}

.merchant_form .status_field fieldset legend {
  width: 40px;
}

.merchant_form .multi_field fieldset legend {
  width: 67px;
}

.merchant_form .text_area_field fieldset legend {
  width: 82px;
}

.scheme_form .low_range_field fieldset legend {
  width: 65px;
}

.scheme_form .high_range_field fieldset legend {
  width: 68px;
}

.scheme_form .numberofInstallments_field fieldset legend {
  width: 127px;
}

.scheme_form .rateofInterest_field fieldset legend {
  width: 88px;
}

.scheme_form .feeofInstallments_field fieldset legend {
  width: 106px;
}

.scheme_form .cashbackType_field fieldset legend {
  width: 86px;
}

.scheme_form .issueCashbackValue_field fieldset legend {
  width: 168px;
}

.scheme_form .issueCashbackPercentage_field fieldset legend {
  width: 127px;
}

.scheme_form .totalCashbackPercentage_field fieldset legend {
  width: 148px;
}

.scheme_form .issueCashbackValue_field fieldset legend {
  width: 118px;
}

.scheme_form .totalCashbackValue_field fieldset legend {
  width: 118px;
}

.scheme_form .startDate_field fieldset legend {
  width: 62px;
}

.scheme_form .endDate_field fieldset legend {
  width: 56px
}

/*******daterpiker******/

.MuiInputLabel_shrink label {
  transform: translate(14px, -6px) scale(0.75);
}

.MuiInputLabel_shrink fieldset legend {
  max-width: 1000px;
  transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
}

.MuiAutocomplete-inputRoot .MuiChip-deletable .MuiChip-label {
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  font-family: "Quicksand-Medium";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  color: #424242;
}

svg.calender_input {
  color: #424242;
  width: 20px;
  height: 20px;
}

.tabs_wraper {
  padding-bottom: 20px;
}

.tabs_wraper .MuiTabs-flexContainer {
  border-bottom: solid 2px #E0E0E0;
}

.tabs_wraper .MuiTabs-flexContainer button span {
  color: #9E9E9E;
}

.tabs_wraper .MuiTabs-flexContainer button.Mui-selected span {
  color: #1BA785;
}

.tabs_wraper .MuiTabs-flexContainer button, .tabs_wraper .MuiTabs-flexContainer button span {
  /* color: #1BA785; */
  font-family: "Quicksand-Bold";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
}

.mccCheckbox {
  display: inline-block !important
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.MuiSelect-outlined.MuiSelect-outlined {
  padding-right: 32px;
  font-family: "Quicksand-Medium";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  color: #757575;
}

.MuiMenu-list {
  outline: 0;
  font-family: "Quicksand-Medium";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  color: #757575;
}

.action_form .MuiInputLabel-outlined {
  font-family: "Quicksand-Medium";
  font-size: 17px;
}

.action_form .MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-family: "Quicksand-Medium";
  font-size: 17px;
}

.tabs_wraper .MuiTabs-flexContainer button span {}