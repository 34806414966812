
.dateRange .rdrDayHovered .rdrDayEndPreview, .rdrDayStartweek {
    border-radius: 0px!important;
  }
  
  .dateRange .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .dateRange .rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .dateRange .rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .dateRange .rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .rdrMonthPicker, .rdrYearPicker {
    
  }
  
  .rdrMonthAndYearWrapper .rdrNextPrevButton {
    background-color: transparent;
    background: transparent;
  }
  
  .rdrDateDisplayWrapper {
    display: none;
  }
  
  .rdrDefinedRangesWrapper {
    display: none;
  }
  
  .rdrDateRangePickerWrapper {
    /* border: solid 1px #ccc; */
    /* border-radius: 10px; */
    box-shadow: 0px 0px 2px 1px #cccc;
  }
  
  .rdrMonthAndYearWrapper .rdrPprevButton i {
    border-width: 6px 6px 6px 8px;
    border-color: transparent rgb(86 168 133) transparent transparent;
    transform: translate(-5px, 0px);
  }
  
  .rdrMonthAndYearWrapper .rdrNextButton i {
    margin: 0px 0 0 7px;
    border-width: 6px 6px 6px 8px;
    border-color: transparent transparent transparent rgb(86 168 133);
    transform: translate(3px, 0px);
  }
  
  .dateRange .rdrDayStartOfMonth .rdrInRange, .dateRange .rdrDayStartOfMonth .rdrEndEdge, .dateRange .rdrDayStartOfWeek .rdrInRange, .dateRange .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 0em;
    border-bottom-left-radius: 0em;
    left: 2px;
  }
  
  .dateRange .rdrDay .rdrEndEdge, .rdrDay.rdrDayHoverd .rdrEndEdge {
    border-top-right-radius: 0 ;
    border-bottom-right-radius: 0;
    right: 2px;
  }
  
  .dateRange .rdrDayEndOfMonth .rdrInRange, .dateRange .rdrDayEndOfMonth .rdrStartEdge, .dateRange .rdrDayEndOfWeek .rdrInRange, .dateRange .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .rdrDay span {
    border-radius: 0!important;
  }
  
  .rdrDay .rdrStartEdge {
    color: #1BA785;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .rdrDay .rdrInRange{
    color:rgba(61,181,74,0.1)!important;
  }
  .dateRange .rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span {
    color: #000;
  }
  
  .dateRange .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .rdrDay.rdrDayToday .rdrDayNumber span:after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #1BA785;
  }
  
  .dateRange {
    position: absolute;
    background: #fff;
  }
  .dateRange span:after,.dateRange div,.dateRange span,.dateRange button{border-radius: 0!important;}
  .dateRange .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{border-radius: 0!important;}
  .rdrDayHovered .rdrDayNumber::after{border-radius: 0!important}
   