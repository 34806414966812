*{
    margin: 0;
    padding: 0;
}
.page{
    /* height: 100vh; */
    /* background-color: #f1eef7; */
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.container{
    margin-top: 2rem;
    margin-bottom: 2rem;
    /* max-height: 300px; */
    width: 300px;
    border-radius: 10px;
    /* background-color: rgba(255, 255, 255, 0.74); */
    background-color: #f1eef7;
    padding-bottom: 1rem;
}
.heading{
    text-align: center;
    /* font-family: Arial, Helvetica, sans-serif; */
    margin-top: 1rem;
    /* font-weight: bold; */
    font-size: 16px;
}
.close-icon{
    margin-left: -25px;
    height: max-content;
    color: white;
    background-color: #51a886;
    border-radius: 4px;
}
.img-holder-cross{
    display: flex;
    margin: auto;
    margin-top: 10px;
    width: 160px;
    height: 140px;
    border-radius: 5px;
}
.img-holder{
    margin: auto;
    width: 160px;
    height: 150px;
    margin-top: 10px;
    border-radius: 5px;
}
.img{
    width: 160px;
    height: 140px;
    object-fit: cover;
    background-color: black;
}
.circleIcon {
    margin-bottom: -60%;
    margin-left: 37%;
    color: white !important;
}
#input[type="file"]{
    display: none;
}
#input1[type="file"]{
    display: none;
}
#input2[type="file"]{
    display: none;
}
.label{
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}
.image-upload{
    margin: auto;
    width: 200px;
    height: 20px;
    background-color: rgb(30, 158, 41);
    color: white;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}
.upload-action{
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 3.4rem;
    /* margin-right: 3.2rem; */
}